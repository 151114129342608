function isLocalTarget() {
  // eslint-disable-next-line no-undef
  return __TARGET_ENV__ === 'local';
}

function isDevTarget() {
  // eslint-disable-next-line no-undef
  return __TARGET_ENV__ === 'dev';
}

function isProdTarget() {
  // eslint-disable-next-line no-undef
  return __TARGET_ENV__ === 'prod';
}

function getTargetEnvName() {
  // eslint-disable-next-line no-undef
  return __TARGET_ENV__.toUpperCase();
}

function isProductionBuild() {
  // eslint-disable-next-line no-undef
  return __NODE_ENV__ === 'production';
}

function isDevelopmentBuild() {
  // eslint-disable-next-line no-undef
  return __NODE_ENV__ === 'development';
}

function isOnClient() {
  // eslint-disable-next-line no-undef
  return __CLIENT__ === true;
}

export {
  isLocalTarget,
  isDevTarget,
  isProdTarget,
  getTargetEnvName,

  isProductionBuild,
  isDevelopmentBuild,

  isOnClient,
};
