function isSaleTeam(userId) {
  return [
    'le5v2NeJjvaTgHjcfMLEcMAXaK72',
    'ICQWPXRBCUcWo34l05KnzpGjvNC3',
  ].includes(userId);
}

export {
  // eslint-disable-next-line import/prefer-default-export
  isSaleTeam,
};
